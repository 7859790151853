import cx from "classnames";
import React from "react";

import Article from "../components/Article";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import ModuleArea from "../components/ModuleArea/ModuleArea";
import SEO from "../components/seo";
import { getBreadcrumbsHidden } from "../hooks/header";
import { useHTMLProcessor } from "../hooks/htmlProcessor";
import { PageContextProvider } from "../hooks/pageContext";

export default function OperationalStatusPageTemplate({ pageContext }) {
  const {
    operationalStatus: {
      databaseId,
      title,
      content,
      date: publishedDate,
      displaySettings: { postSingleShowFeaturedImage },
      featuredImage,
      modifiedGmt: modifiedDate,
      modularityModules: { contentArea, sliderArea },
      operationalStatusCategories: { nodes: operationalStatusCategories } = {},
      contentMedia,
    },
  } = pageContext;

  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  const isBreadcrumbsHidden = getBreadcrumbsHidden();

  return (
    <PageContextProvider
      value={{
        ...pageContext,
        postId: databaseId,
        postTypeName: "operationalStatus",
      }}
    >
      <Layout sliderArea={sliderArea}>
        <SEO title={title} />
        {!isBreadcrumbsHidden && <Breadcrumb />}
        <Article
          title={title}
          preamble={{
            text: processedContent.preamble,
          }}
          publishedDate={publishedDate}
          modifiedDate={modifiedDate}
          featuredImage={postSingleShowFeaturedImage && featuredImage}
          taxonomyTerms={{ operationalStatusCategories }}
          className="o-margin-top-large"
        >
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit c-article">
              {processedContent.content}
            </div>
          </div>
          <div className="o-grid-row">
            {contentArea && <ModuleArea area={contentArea} />}
          </div>
        </Article>
      </Layout>
    </PageContextProvider>
  );
}
